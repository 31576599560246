import { addDays, isBefore } from "date-fns";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: FormState = {
	formGuid: null,
	createdAt: new Date(),
};

export const useStore = create<FormState & FormActions>()(
	persist(
		devtools((set) => ({
			...initialState,
			setFormGuid: (formGuid) => set((state) => ({ ...state, formGuid })),
			reset: () => set(initialState),
		})),
		{
			name: "form-store",
			onRehydrateStorage: (state) => {
				//if createdAt is older than 1 day, we reset the stores
				if (isBefore(state.createdAt, addDays(new Date(), -1))) {
					localStorage.clear();
				}
			},
		},
	),
);

export type FormState = {
	formGuid: string | null;
	createdAt: Date;
};

type FormActions = {
	setFormGuid: (formGuid: string) => void;
	reset: () => void;
};
