import { PrivateRoute } from "@/lib/PrivateRoute.tsx";
import FilloutAmfFormPage from "@/pages/FilloutAmfFormPage";

export const privateRoutes = [
	{
		path: "/",
		element: (
			<PrivateRoute>
				<FilloutAmfFormPage />
			</PrivateRoute>
		),
	},
];
