import { useCallback } from "react";
import { useAuthStore } from "@/stores/authStore.tsx";
import { useStore } from "@/stores/useStore.ts";

export const useResetStores = () => {
	const resetStore = useStore((state) => state.reset);
	const resetAuthStore = useAuthStore((state) => state.reset);
	return useCallback(() => {
		resetStore();
		resetAuthStore();
	}, [resetStore, resetAuthStore]);
};
