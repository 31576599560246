import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: AuthStoreState = {
	partnershipId: null,
	lastBrokerPartnershipId: null,
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
	persist(
		devtools((set) => ({
			...initialState,
			setPartnershipId: (partnershipId) => set({ partnershipId }),
			setLastBrokerPartnershipId: (lastBrokerPartnershipId) =>
				set({ lastBrokerPartnershipId }),
			reset: () => set(initialState),
		})),
		{
			name: "auth-store",
			partialize: (state) =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) => !["isFirstVisit", "brokerApiKey"].includes(key),
					),
				),
		},
	),
);

type AuthStoreState = {
	partnershipId: string | null;
	lastBrokerPartnershipId: string | null;
};

type AuthStoreActions = {
	setPartnershipId: (brokerApiKey: string) => void;
	setLastBrokerPartnershipId: (lastBrokerApiKey: string) => void;
	reset: () => void;
};
