import * as Sentry from "@sentry/react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { privateRoutes } from "./private";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const Routes = () => {
	const routes = useSentryRoutes([...privateRoutes]);
	return routes;
};

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};
