import { useEffect } from "react";
import { useAuthStore } from "@/stores/authStore.tsx";
import { useLocation, useSearchParams } from "react-router-dom";

export const useAuthQueryParamsSetter = () => {
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const { partnershipIdFromLocalstorage } = useAuthStore((state) => ({
		partnershipIdFromLocalstorage: state.partnershipId,
		setPartnershipId: state.setPartnershipId,
	}));

	useEffect(() => {
		const partnershipIdFromUrlPath = searchParams.get("partnership_id");

		if (partnershipIdFromLocalstorage && !partnershipIdFromUrlPath) {
			window.history.replaceState(
				undefined,
				"",
				`?partnership_id=${partnershipIdFromLocalstorage}`,
			);
		}
	}, [location.pathname]);
};
